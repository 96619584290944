import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Flex, Text, Button } from '@chakra-ui/react';

import TableTemplateMobile from '@components/tableTemplateMobile';
import TableTemplateMobileSkeleton from '@components/tableTemplateSkeletonMobile';

import { FinishingTableList, initialState, TableListComingSoon, TableListDone } from './const';
import AllLiveListScreen from '../../components/allLiveListScreen';
import { fetchListLiveMobile } from '../../helpres/crud';

import './styles.scss';

const SectionListLiveMobile = ({ refetchTargetHandler }) => {
    const navigate = useNavigate();
    const [state, setState] = useState({});
    const [finishingLiveDataState, setFinishingLiveDataState] = useState(initialState);
    const [comingSoonLiveDataState, setComingSoonLiveDataState] = useState(initialState);
    const [doneLiveDataState, setDoneLiveDataState] = useState(initialState);
    const { isOpenShowMore, selectedTableType, selectedHeadTitle } = state;

    const onChangeState = (newState) => setState({ ...state, ...newState });

    const onChangeFinishingLiveDataState = (newState) =>
        setFinishingLiveDataState({ ...finishingLiveDataState, ...newState });

    const onChangeComingSoonLiveDataState = (newState) =>
        setComingSoonLiveDataState({ ...comingSoonLiveDataState, ...newState });

    const onChangeDoneLiveDataState = (newState) => setDoneLiveDataState({ ...doneLiveDataState, ...newState });

    const fetchingListLiveData = () => {
        fetchListLiveMobile({
            onChangeState: onChangeFinishingLiveDataState,
            queries: {
                limit: 2,
                page: 1,
                isFinish: false,
                endedAt: `lte.${moment(new Date()).utc().toISOString()}`,
            },
        });

        fetchListLiveMobile({
            onChangeState: onChangeComingSoonLiveDataState,
            queries: {
                limit: 2,
                page: 1,
                isFinish: false,
                startedAt: `gte.${moment(new Date()).utc().toISOString()}`,
                sort: 'startedAt.asc',
            },
        });

        fetchListLiveMobile({
            onChangeState: onChangeDoneLiveDataState,
            queries: {
                limit: 2,
                page: 1,
                isFinish: true,
                sort: 'startedAt.desc',
            },
        });
    };

    useEffect(() => {
        /* eslint-disable */

        fetchingListLiveData();
    }, []);

    return (
        <Box className="section-list-live__container__mobile">
            {/* <Box className="item">
                <Flex className="head">
                    <Text className="label-list">Selesaikan Live:</Text>
                    <Button
                        className="button"
                        onClick={() =>
                            onChangeState({
                                selectedTableType: 'finishing-live',
                                selectedHeadTitle: 'Selesaikan Live',
                                isOpenShowMore: true,
                            })
                        }
                    >
                        Lihat Semua
                    </Button>
                </Flex>
                {finishingLiveDataState?.isLoading ? (
                    <TableTemplateMobileSkeleton row={2} />
                ) : (
                    <TableTemplateMobile
                        tableList={FinishingTableList}
                        data={finishingLiveDataState?.data?.schedules}
                        isDynamicTable={true}
                        usePagination={false}
                        onClickRow={(value) => navigate(`/schedule-submission/${value?.id}?title=Finish`)}
                    />
                )}
            </Box> */}
            <Box className="item">
                <Flex className="head">
                    <Text className="label-list">Jadwal live mendatang:</Text>
                    <Button
                        className="button"
                        onClick={() =>
                            onChangeState({
                                selectedTableType: 'comingsoon-live',
                                selectedHeadTitle: 'Jadwal live mendatang',
                                isOpenShowMore: true,
                            })
                        }
                    >
                        Lihat Semua
                    </Button>
                </Flex>
                {comingSoonLiveDataState?.isLoading ? (
                    <TableTemplateMobileSkeleton row={2} />
                ) : (
                    <TableTemplateMobile
                        tableList={TableListComingSoon}
                        data={comingSoonLiveDataState?.data?.schedules}
                        isDynamicTable={true}
                        usePagination={false}
                        onClickRow={(value) => navigate(`/schedule/${value?.id}?title=Detail`)}
                    />
                )}
            </Box>
            <Box className="item">
                <Flex className="head">
                    <Text className="label-list">Jadwal live selesai:</Text>
                    <Button
                        className="button"
                        onClick={() =>
                            onChangeState({
                                selectedTableType: 'done-live',
                                selectedHeadTitle: 'Jadwal live selesai',
                                isOpenShowMore: true,
                            })
                        }
                    >
                        Lihat Semua
                    </Button>
                </Flex>
                {doneLiveDataState?.isLoading ? (
                    <TableTemplateMobileSkeleton row={2} />
                ) : (
                    <TableTemplateMobile
                        tableList={TableListDone}
                        data={doneLiveDataState?.data?.schedules}
                        isDynamicTable={true}
                        usePagination={false}
                        onClickRow={(value) => navigate(`/schedule/${value?.id}?title=Detail`)}
                    />
                )}
            </Box>

            {/** //--------------------------------- SCREEN ----------------------------    **/}

            {isOpenShowMore && (
                <AllLiveListScreen
                    onChangeMainState={onChangeState}
                    selectedTableType={selectedTableType}
                    selectedHeadTitle={selectedHeadTitle}
                    onClickRow={({ value }) =>
                        navigate(
                            selectedTableType === 'finishing-live'
                                ? `/schedule-submission/${value?.id}?title=Finish`
                                : `/schedule/${value?.id}?title=Detail`
                        )
                    }
                />
            )}
        </Box>
    );
};

export default SectionListLiveMobile;
