import moment from 'moment';
import { isEmpty } from 'lodash';
import CountUp from 'react-countup';
import { Flex, Text, Box } from '@chakra-ui/react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import DatePicker from 'react-datepicker';

import { AchievementIcon, ChartIcon1, CalendarIcon } from '@assets/icons';

import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import './styles.scss';
import { getCurrency } from '@helpers/util';

const SectionTargetMobile = ({ target, onChangeState }) => {
    const currency = getCurrency();
    const { duration, livestreamingResult, revenue, upcomingLive, selectedMonth } = target;

    return (
        <Box className="section-target__container__mobile">
            <DatePicker
                selected={selectedMonth}
                onChange={(value) => onChangeState({ selectedMonth: value })}
                placeholderText="Pilih bulan"
                dateFormat="MMM yyyy"
                wrapperClassName="filter-month"
                showMonthYearPicker
                showIcon
                icon={<CalendarIcon color="var(--main-color)" />}
            />

            <Flex className="target-container">
                <Flex className="item">
                    <Box className="circular-target item-box">
                        <Box position="relative" w="max-content">
                            <Text className="percentage-text">
                                <CountUp
                                    end={
                                        duration?.percentage === 0 && duration?.totalMinutes !== 0
                                            ? 100
                                            : duration?.percentage
                                    }
                                    duration={4}
                                />
                                %
                            </Text>
                            <CircularProgressbar
                                value={
                                    duration?.percentage === 0 && duration?.totalMinutes !== 0
                                        ? 100
                                        : duration?.percentage
                                }
                                strokeWidth={12}
                                styles={buildStyles({
                                    pathColor: `var(--main-color)`,
                                })}
                            />
                        </Box>
                    </Box>
                    <Box className="item-box">
                        <Text className="title-label">Total Live</Text>
                        <Text className="text-info">
                            <ChartIcon1 color="var(--main-color)" />
                            {duration.hoursInString || '0 Jam'}
                        </Text>
                    </Box>
                    <Box className="item-box">
                        <Text className="title-label">Target Live</Text>
                        <Text className="text-info">
                            <AchievementIcon color="var(--main-color)" />
                            {duration.targetInstring || '0 Jam'}
                        </Text>
                    </Box>
                </Flex>
                <Flex className="item">
                    <Box className="circular-target item-box">
                        <Box position="relative" w="max-content">
                            <Text className="percentage-text">
                                <CountUp
                                    duration={4}
                                    end={
                                        revenue?.percentage === 0 && revenue?.totalRevenue !== 0
                                            ? 100
                                            : revenue?.percentage
                                    }
                                />
                                %
                            </Text>
                            <CircularProgressbar
                                value={
                                    revenue?.percentage === 0 && revenue?.totalRevenue !== 0 ? 100 : revenue?.percentage
                                }
                                strokeWidth={12}
                                styles={buildStyles({
                                    pathColor: `var(--main-color)`,
                                })}
                            />
                        </Box>
                    </Box>
                    <Box className="item-box">
                        <Text className="title-label">Penghasilan</Text>
                        <Text className="text-info">
                            <ChartIcon1 color="var(--main-color)" />
                            <span>{currency}</span> <CountUp end={revenue?.totalRevenue} duration={4} />
                        </Text>
                    </Box>
                    <Box className="item-box">
                        <Text className="title-label">Target </Text>
                        <Text className="text-info">
                            <AchievementIcon color="var(--main-color)" />
                            <span>{currency}</span> <CountUp end={revenue?.target} duration={4} />
                        </Text>
                    </Box>
                </Flex>

                <Flex>
                    <Flex className="item" alignItems="flex-start !important">
                        <Box p="0 8px">
                            <Text className="text-1">Belum diselesaikan</Text>
                            <Text className="text-2 red-color">{livestreamingResult?.unfinishedLivestream} Live</Text>
                            <Flex alignItems="center">
                                <Text className="text-3">
                                    {Number(livestreamingResult?.finishedLivestream) +
                                        Number(livestreamingResult?.canceledLivestreams)}{' '}
                                    live telah diselesaikan
                                </Text>
                            </Flex>
                        </Box>
                    </Flex>
                    <Flex className="item" alignItems="flex-start !important">
                        <Box p="0 8px">
                            <Text className="text-1">Live mendatang</Text>
                            {!isEmpty(upcomingLive?.startedAt) && (
                                <>
                                    <Text className="text-2 red-color">
                                        {moment(upcomingLive?.startedAt).format('HH:mm')}-{' '}
                                        {moment(upcomingLive?.endedAt).format('HH:mm')}
                                    </Text>
                                    <Flex alignItems="center">
                                        <Text className="text-3">
                                            {moment(upcomingLive?.startedAt).format('dddd, Do MMMM')}
                                        </Text>
                                    </Flex>
                                </>
                            )}

                            {isEmpty(upcomingLive?.startedAt) && <Text className="text-2 red-color">Tidak ada</Text>}
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default SectionTargetMobile;
