//---------------- 3RD PARTY LIBRARY ------------------------------------------
import moment from 'moment';
import { useContext, useState } from 'react';
import { Flex, Box, Text, Button } from '@chakra-ui/react';
import { FilterMultipleSelectSearch, FilterSelectSearch, FilterSelect } from '@components/globalFilter';
import DatePickerWeek from 'rsuite/DatePicker';
import { useDispatch } from 'react-redux';

//---------------- GLOBAL COMPONENT ------------------------------------------
import {
    MarketPlaceIcon,
    SettingIcon2,
    CouponIcon,
    PeopleIcon1,
    LeftArrowIconBold,
    RightArrowIconBold,
    StudioIcon,
    IconHost,
    ScheduleIcon,
    ResetIcon,
    IconChart,
    DownloadIcon,
} from '@assets/icons';
import { ScheduleContext } from '@pages/schedule';
import { optionsFilterFormatted } from '@pages/schedule/helpers/utlis';

//---------------- LOCAL COMPONENT ------------------------------------------
import FilterSettingPopUp from './components/filterPopUp';
import UploadPopUp from './components/uploadPopUp';

//---------------- UTILS ------------------------------------------
import { isFilterActive } from '../../helpers/utlis';
import { onResetFilter } from '../../helpers/event';
import { exportScheduleHandler } from '@pages/schedule/helpers/crud';
import { calendarTypeOption, calendarTypeMobileOption } from './const';

import 'rsuite/dist/rsuite.min.css';
import './styles.scss';

const SectionHead = ({ calendarRef, mainState, onUpdateMainState }) => {
    const dispatch = useDispatch();
    const { assets } = useContext(ScheduleContext);

    const [state, setState] = useState({
        filterShow: ['brand', 'ecommerce', 'account', 'host'],
        isOpenSettingPopup: false,
        isOpenUploadPopup: false,
    });

    const onChangeState = (newState) => setState({ ...state, ...newState });
    const isOpenFilter = (type) => state?.filterShow?.includes(type);
    const onClosePopup = () => onChangeState({ isOpenSettingPopup: false });

    const onChangeWeek = ({ type }) => {
        let calendar = calendarRef.current.getApi();
        type === 'next' ? calendar.next() : calendar.prev();
    };

    const onChangeWeekCalendar = (date) => {
        let calendar = calendarRef.current.getApi();
        calendar.gotoDate(date);
    };

    const onChangeCalendar = (selectedCalendarType) => {
        onUpdateMainState({ calendarType: selectedCalendarType });
    };
    return (
        <Flex className="section-header__container__schedule">
            {/** -------------------------- LEFT SIDE ----------------------------**/}
            <Flex className="left-side">
                <Flex className="day-nav">
                    <Box className="box-icon" onClick={() => onChangeWeek({ type: 'prev' })}>
                        <LeftArrowIconBold color="white" />
                    </Box>
                    <Box className="box-icon" onClick={() => onChangeWeek({ type: 'next' })}>
                        <RightArrowIconBold color="white" />
                    </Box>
                </Flex>
                <DatePickerWeek
                    showWeekNumbers
                    onChangeCalendarDate={onChangeWeekCalendar}
                    oneTap
                    toggleAs={() => (
                        <Flex className="date-week__picker">
                            <Box className="icon-week">
                                <ScheduleIcon color="grey" />
                            </Box>
                            <Text className="date-range-text">
                                {moment(mainState?.currentStartDate).format('Do MMM')} -{' '}
                                {moment(mainState?.currentEndDate).subtract(1, 'days').format('Do MMM YYYY')}
                            </Text>
                        </Flex>
                    )}
                />

                <Box className="view-mobile__only filter-date__mobile">
                    {/* <FilterSelect
                        icon={<IconChart color="black" />}
                        placeholder="Pilih Type"
                        filterName="calendarType"
                        margin="0 8px"
                        value={mainState?.calendarType}
                        options={calendarTypeMobileOption}
                        isLoading={assets?.isLoadingAssets}
                        onChange={({ value }) => onChangeCalendar(value)}
                        isMobile={true}
                    /> */}
                </Box>

                <Button
                    className="upload-button"
                    marginLeft={8}
                    onClick={() => onChangeState({ isOpenUploadPopup: true })}
                >
                    <DownloadIcon color="white" size="16" styles={{ marginRight: '8px' }} />
                    Upload
                </Button>
                <Button className="upload-button" marginLeft={4} onClick={() => exportScheduleHandler({ dispatch })}>
                    <DownloadIcon color="white" size="16" styles={{ marginRight: '8px' }} />
                    Export
                </Button>
            </Flex>

            {state.isOpenUploadPopup && <UploadPopUp onChangeHeadState={onChangeState} />}

            {/** -------------------------- RIGHT SIDE ----------------------------**/}

            <Flex className="right-side">
                <FilterSelect
                    icon={<IconChart color="black" />}
                    placeholder="Pilih Type"
                    filterName="calendarType"
                    margin="0 8px"
                    value={mainState?.calendarType}
                    options={calendarTypeOption}
                    isLoading={assets?.isLoadingAssets}
                    onChange={({ value }) => onChangeCalendar(value)}
                    isReset={false}
                />
                {isOpenFilter('brand') && (
                    <FilterSelectSearch
                        icon={<CouponIcon color="black" />}
                        placeholder="Brand"
                        value={mainState?.brand}
                        filterName="brands"
                        margin="0 8px"
                        options={optionsFilterFormatted(assets?.brands)}
                        isLoading={assets?.isLoadingAssets}
                        onChange={({ type, value }) => onUpdateMainState({ [type]: value })}
                    />
                )}
                {isOpenFilter('ecommerce') && (
                    <FilterSelectSearch
                        icon={<MarketPlaceIcon color="black" />}
                        placeholder="Ecommerce"
                        filterName="ecommerce"
                        margin="0 8px"
                        value={mainState?.ecommerce}
                        options={optionsFilterFormatted(assets?.ecommerces)}
                        isLoading={assets?.isLoadingAssets}
                        onChange={({ type, value }) => onUpdateMainState({ [type]: value })}
                    />
                )}

                {isOpenFilter('studio') && (
                    <FilterSelectSearch
                        icon={<StudioIcon color="grey" />}
                        placeholder="Studio"
                        value={mainState?.studio}
                        filterName="studio"
                        margin="0 8px"
                        options={optionsFilterFormatted(assets?.studios)}
                        isLoading={assets?.isLoadingAssets}
                        onChange={({ type, value }) => onUpdateMainState({ [type]: value })}
                    />
                )}

                {isOpenFilter('host') && (
                    <FilterSelectSearch
                        icon={<IconHost color="grey" />}
                        placeholder="Host"
                        margin="0 8px"
                        value={mainState?.host}
                        filterName="host"
                        options={optionsFilterFormatted(assets?.hosts)}
                        isLoading={assets?.isLoadingAssets}
                        onChange={({ type, value }) => onUpdateMainState({ [type]: value })}
                    />
                )}

                {isOpenFilter('operator') && (
                    <FilterSelectSearch
                        icon={<PeopleIcon1 color="black" />}
                        placeholder="Operator"
                        margin="0 8px"
                        value={mainState?.operator}
                        filterName="operator"
                        isLoading={assets?.isLoadingAssets}
                        options={optionsFilterFormatted(assets?.operators)}
                        onChange={({ type, value }) => onUpdateMainState({ [type]: value })}
                    />
                )}

                {isOpenFilter('account') && (
                    <FilterMultipleSelectSearch
                        icon={<PeopleIcon1 color="black" />}
                        placeholder="Account"
                        margin="0 8px"
                        value={mainState?.account}
                        filterName="account"
                        isLoading={assets?.isLoadingAssets}
                        options={optionsFilterFormatted(assets?.accounts)}
                        onChange={({ type, value }) => onUpdateMainState({ [type]: value })}
                    />
                )}

                <Flex className="filter-setting__container">
                    {isFilterActive({ mainState }) && (
                        <Box cursor="pointer" onClick={() => onResetFilter({ onUpdateMainState })}>
                            <ResetIcon color="var(--main-color)" />
                        </Box>
                    )}
                    <Box
                        cursor="pointer"
                        ml="12px"
                        onClick={() => onChangeState({ isOpenSettingPopup: !state?.isOpenSettingPopup })}
                    >
                        <SettingIcon2 color="black" />
                    </Box>
                    {state?.isOpenSettingPopup && (
                        <FilterSettingPopUp
                            onChangeState={onChangeState}
                            state={state}
                            onClosePopup={onClosePopup}
                            initiateFilterShow={state?.filterShow}
                        />
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SectionHead;
