import Cookies from 'universal-cookie';
import queryString from 'query-string';
import moment from 'moment';
import Axios from '@services/axios';
import { popupHandler } from '@store/list/popup';
import { dataURItoBlob, formattingPayloadHandler } from './utils';

export const fetchTargetHandler = async ({ onChangeState, target }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeState({
        isLoading: true,
    });

    try {
        const { data } = await Axios({
            method: 'POST',
            url: '/productivity-host/summary',
            headers: { Authorization: 'Bearer ' + userToken },
            data: {
                date: moment(target?.selectedMonth).endOf('month').format('YYYY-MM-DD'),
            },
        });

        const { duration, livestreamingResult, revenue, upcomingLive } = data?.data;

        onChangeState({
            duration,
            livestreamingResult,
            revenue,
            upcomingLive,
            isLoading: false,
        });
    } catch (error) {
        console.log(error, '<<<<');
        onChangeState({
            isLoading: false,
        });
    }
};

export const fetchListLiveMobile = async ({ onChangeState, page, limit, queries, sort }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeState({
        isLoading: true,
    });

    const filterStringify = queryString.stringify(
        {
            limit,
            page,
            sort,
            ...queries,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/productivity-host/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onChangeState({
            data: data?.data,
            isLoading: false,
        });
    } catch (error) {
        console.log(error, '<<<<');
        onChangeState({
            isLoading: false,
        });
    }
};

// ------------------------------------- FINISHING LIVE CRUD ---------------------

export const fetchLiveDetail = async ({ onChangeState, id }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeState({
        isLoading: true,
    });

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/productivity-host/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onChangeState({
            schedule: data?.data?.schedule,
            isLoading: false,
        });
    } catch (error) {
        console.log(error, '<<<<');
        onChangeState({
            isLoading: false,
        });
    }
};

export const fetchAdditionalInfoLive = async ({ onChangeState, id }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeState({
        isLoading: true,
    });

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/productivity-host/detail/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        const { productHighlights, coins, vouchers, promotions } = data?.data;
        onChangeState({
            productHighlights,
            coins,
            vouchers,
            promotions,
            isLoading: false,
        });
    } catch (error) {
        console.log(error, '<<<<');
        onChangeState({
            isLoading: false,
        });
    }
};

export const fetchRevenueLiveDetail = async ({ onChangeState, id }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    onChangeState({
        isLoading: true,
    });

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/productivity-host/revenue/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });

        onChangeState({
            data: data?.data?.revenues,
            isLoading: false,
        });
    } catch (error) {
        console.log(error, '<<<<');
        onChangeState({
            isLoading: false,
        });
    }
};

export const handleTakePhoto = async ({
    uri,
    onChangeState,
    accountId,
    state,
    onCloseCamera,
    dispatch,
    isFromGallery,
}) => {
    let file = uri;

    if (!isFromGallery) {
        file = dataURItoBlob(uri);
    }

    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const formData = new FormData();
    formData.append('file', file);

    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Sedang mengupload gambar.....',
        })
    );

    try {
        const { data } = await Axios({
            method: 'POST',
            url: '/productivity-host/upload',
            headers: { Authorization: 'Bearer ' + userToken },
            data: formData,
        });

        onChangeState({
            form: {
                ...state.form,
                [accountId]: {
                    ...state.form[accountId],
                    fileId: data?.data?.fileId,
                    fileUrl: data?.data?.url,
                },
            },
        });

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil mengupload image',
            })
        );
    } catch (error) {
        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Gagal mengupload image',
            })
        );
        console.log(error);
    } finally {
        if (!isFromGallery) {
            onCloseCamera();
        }
    }
};

export const onSubmitHandler = async ({ state, dispatch, refetchDataHandler, onChangeMainState }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    dispatch(
        popupHandler({
            popUpType: 'popup-waiting',
            popUpTitle: 'Sedang mengirim data.....',
        })
    );

    try {
        await Axios({
            method: 'POST',
            url: `/productivity-host`,
            headers: { Authorization: 'Bearer ' + userToken },
            data: formattingPayloadHandler({ state }),
        });

        refetchDataHandler();

        dispatch(
            popupHandler({
                popUpType: 'popup-success',
                popUpTitle: 'Berhasil mengirim data',
            })
        );

        onChangeMainState({ isOpenFinishing: false });
    } catch (error) {
        console.log(error);

        dispatch(
            popupHandler({
                popUpType: 'popup-failed',
                popUpTitle: 'Gagal mengirim data',
            })
        );
    }
};
