import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Text, Flex, Button } from '@chakra-ui/react';
import queryString from 'query-string';
import { upperFirst } from 'lodash';
import { CameraFieldComponent, InputComponent, TimePickerComponent } from '@components/formFields';
import { separatorComma } from '@helpers/util';
import ScreenTemplateMobile from '@components/screenTemplateMobile';

import { handleTakePhoto } from '@pages/mainDashboard/helpres/crud';
import { getInputWording, getDate, getRangeTime } from '@pages/mainDashboard/helpres/utils';
import { onChangeFormField } from '@pages/mainDashboard/helpres/events';
import { DetailInformationSkeleton, FormReportLiveInformationSkeleton } from '@pages/mainDashboard/components/skeleton';
import { PeopleIcon, CloseIcon } from '@assets/icons';
import ImageS3 from '@components/imageS3';
import { onSubmitHandler } from './helpers/crud';
import { fetchLiveDetail } from './helpers/crud';
import { onShowingCancelLivePopUp } from './helpers/events';
import './styles.scss';

const ScheduleSubmission = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedLiveEvent = useParams();
    const location = useLocation();
    const { title } = queryString.parse(location?.search);

    const [state, setState] = useState({ isLoading: true, form: {} });
    const { schedule, isLoading } = state;

    const onChangeState = (newState) => setState({ ...state, ...newState });

    useEffect(() => {
        /* eslint-disable */
        fetchLiveDetail({ onChangeState, id: selectedLiveEvent?.id, title });
    }, []);

    return (
        <ScreenTemplateMobile onBack={() => navigate(-1)} headTitle={title}>
            <Box className="finishlive-screen__container__maindashboard">
                {isLoading && (
                    <>
                        <DetailInformationSkeleton />
                        <FormReportLiveInformationSkeleton />
                    </>
                )}

                {!isLoading && (
                    <>
                        <Box className="finishinfo">
                            <Text className="label-section">Informasi Live:</Text>
                            <Box className="finishinfo__item">
                                <Flex justifyContent="space-between" alignItems="center" w="100%">
                                    <Text className="event-title">{upperFirst(schedule?.title)}</Text>
                                    <Flex>
                                        <Box
                                            className="event-type"
                                            bg={schedule?.scheduleType === 'Block' ? 'var(--main-color)' : '#4cbfbf'}
                                        >
                                            {schedule?.scheduleType}
                                        </Box>
                                        <Box className="event-studio">{schedule?.studio?.name}</Box>
                                    </Flex>
                                </Flex>
                                <Flex justifyContent="space-between">
                                    <Text className="event-date">
                                        {getDate({ schedule })}, {getRangeTime({ schedule })}
                                    </Text>
                                </Flex>
                            </Box>
                            <Box className={`more-detail-box__container`}>
                                <Flex alignItems="center">
                                    <Text className="event-items">Account</Text>
                                    <Flex className="items-container">
                                        {schedule?.accountLivestreams?.map((item, idx) => (
                                            <Text className="event-item">
                                                {item?.name} <span>({item?.ecommerce?.name}) </span>
                                                {idx !== schedule?.accountLivestreams?.length - 1 && ','}
                                            </Text>
                                        ))}
                                    </Flex>
                                </Flex>

                                <Flex alignItems="center">
                                    <Text className="event-items">Operator</Text>
                                    <Flex className="items-container">
                                        {schedule?.operators?.map((item, idx) => (
                                            <Text className="event-item">
                                                {item?.name}
                                                {idx !== schedule?.accountLivestreams?.length - 1 && ','}
                                            </Text>
                                        ))}
                                    </Flex>
                                </Flex>

                                <Flex alignItems="center">
                                    <Text className="event-items">Host</Text>
                                    <Flex className="items-container">
                                        {schedule?.hosts?.map((item, idx) => (
                                            <Text className="event-item">
                                                {item?.name}
                                                {idx !== schedule?.accountLivestreams?.length - 1 && ','}
                                            </Text>
                                        ))}
                                    </Flex>
                                </Flex>
                            </Box>
                        </Box>
                        <Box className="finishinfo">
                            <Flex justifyContent="space-between">
                                <Text className="label-section">Silahkan isi data live berikut :</Text>

                                <Button
                                    className="cancel-live"
                                    onClick={() => onShowingCancelLivePopUp({ state, dispatch, navigate })}
                                >
                                    Cancel Live
                                </Button>
                            </Flex>

                            {schedule?.accountLivestreams?.map((item, idx) => (
                                <Box className="livestream-report">
                                    <Flex className="account-name__container">
                                        <PeopleIcon size="16" color="white" />
                                        <Text className="account-name">
                                            {item?.name} <span>|</span>
                                            {item?.ecommerce?.name}
                                        </Text>
                                    </Flex>

                                    <Box className="input-container">
                                        <InputComponent
                                            label={getInputWording({
                                                ecommerce: item?.ecommerce?.name,
                                                type: 'revenue',
                                            })}
                                            placeholder={`Masukan ${getInputWording({
                                                ecommerce: item?.ecommerce?.name,
                                                type: 'revenue',
                                            })}`}
                                            type="text"
                                            fieldName="revenue"
                                            onChange={({ fieldName, value }) =>
                                                onChangeFormField({
                                                    fieldName,
                                                    value,
                                                    onChangeState,
                                                    accountId: item?.id,
                                                    state,
                                                })
                                            }
                                            value={separatorComma(state?.form[item.id]?.revenue)}
                                        />
                                        <InputComponent
                                            label={getInputWording({
                                                ecommerce: item?.ecommerce?.name,
                                                type: 'viewer',
                                            })}
                                            placeholder={`Masukan ${getInputWording({
                                                ecommerce: item?.ecommerce?.name,
                                                type: 'viewer',
                                            })}`}
                                            type="text"
                                            fieldName="viewer"
                                            onChange={({ fieldName, value }) =>
                                                onChangeFormField({
                                                    fieldName,
                                                    value,
                                                    onChangeState,
                                                    accountId: item?.id,
                                                    state,
                                                })
                                            }
                                            value={separatorComma(state?.form[item.id]?.viewer)}
                                        />
                                        <InputComponent
                                            label="Pembeli"
                                            placeholder={`Masukan Pembeli`}
                                            type="number"
                                            fieldName="buyer"
                                            onChange={({ fieldName, value }) =>
                                                onChangeFormField({
                                                    fieldName,
                                                    value,
                                                    onChangeState,
                                                    accountId: item?.id,
                                                    state,
                                                })
                                            }
                                            value={state?.form[item.id]?.buyer}
                                        />
                                        <InputComponent
                                            label={getInputWording({
                                                ecommerce: item?.ecommerce?.name,
                                                type: 'ctrPercentage',
                                            })}
                                            placeholder={`Masukan ${getInputWording({
                                                ecommerce: item?.ecommerce?.name,
                                                type: 'ctrPercentage',
                                            })}`}
                                            type="number"
                                            fieldName="ctrPercentage"
                                            onChange={({ fieldName, value }) =>
                                                onChangeFormField({
                                                    fieldName,
                                                    value,
                                                    onChangeState,
                                                    accountId: item?.id,
                                                    state,
                                                })
                                            }
                                            value={state?.form[item.id]?.ctrPercentage}
                                        />
                                        <TimePickerComponent
                                            fieldName="duration"
                                            onChange={({ fieldName, value }) =>
                                                onChangeFormField({
                                                    fieldName,
                                                    value,
                                                    onChangeState,
                                                    accountId: item?.id,
                                                    state,
                                                })
                                            }
                                            label={getInputWording({
                                                ecommerce: item?.ecommerce?.name,
                                                type: 'duration',
                                            })}
                                            value={state?.form[item.id]?.duration}
                                            type="second"
                                            disabled={idx > 0}
                                        />

                                        <TimePickerComponent
                                            fieldName="avgViewDuration"
                                            onChange={({ fieldName, value }) =>
                                                onChangeFormField({
                                                    fieldName,
                                                    value,
                                                    onChangeState,
                                                    accountId: item?.id,
                                                    state,
                                                })
                                            }
                                            label={getInputWording({
                                                ecommerce: item?.ecommerce?.name,
                                                type: 'avgViewDuration',
                                            })}
                                            value={state?.form[item.id]?.avgViewDuration}
                                            type="second"
                                        />
                                        <CameraFieldComponent
                                            fieldName="image"
                                            label="Upload Photo Dashboard"
                                            handleTakePhoto={({ uri, onCloseCamera, isFromGallery }) =>
                                                handleTakePhoto({
                                                    uri,
                                                    onChangeState,
                                                    accountId: item?.id,
                                                    state,
                                                    onCloseCamera,
                                                    dispatch,
                                                    isFromGallery,
                                                })
                                            }
                                            value={state?.form[item.id]?.fileId && 'image.png'}
                                        />
                                        <Box
                                            className="image-account__container"
                                            display={
                                                state?.form[item.id]?.fileId || state?.form[item.id]?.fileUrl
                                                    ? ''
                                                    : 'none'
                                            }
                                        >
                                            <ImageS3
                                                fileId={state?.form[item.id]?.fileId}
                                                fileUrl={state?.form[item.id]?.fileUrl}
                                            />
                                            <Box
                                                className="remove-image__button"
                                                onClick={() =>
                                                    onChangeFormField({
                                                        fieldName: 'fileId',
                                                        value: null,
                                                        onChangeState,
                                                        accountId: item?.id,
                                                        state,
                                                    })
                                                }
                                            >
                                                <CloseIcon color="white" />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                            <Button
                                className="submit-button"
                                onClick={() =>
                                    onSubmitHandler({
                                        navigate,
                                        state,
                                        dispatch,
                                        navigate,
                                        isEdit: title === 'Edit',
                                    })
                                }
                            >
                                {title === 'Edit' ? 'Update' : ' Submit'}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </ScreenTemplateMobile>
    );
};

export default ScheduleSubmission;
