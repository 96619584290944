import { Text, Button, Flex } from '@chakra-ui/react';
import moment from 'moment';

const tableList = [
    {
        name: 'Event Title',
        width: '120px',
        value: 'title',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Tanggal',
        width: '80px',
        value: 'startedAt',
        component: ({ value }) => <Text className="column-text">{moment(value).format('Do MMM YYYY')}</Text>,
    },
    {
        name: 'Mulai',
        width: '60px',

        value: 'startedAt',
        component: ({ value }) => <Text className="column-text">{moment(value).format('HH:mm')}</Text>,
    },

    {
        name: 'Selesai',
        width: '60px',
        value: 'endedAt',
        component: ({ value }) => <Text className="column-text">{moment(value).format('HH:mm')}</Text>,
    },
    {
        name: 'Account Livestream',
        flex: 1,
        value: 'accountLivestreams',
        component: ({ value }) => (
            <Flex>
                {value?.map((item) => (
                    <Text className="column-text column-account">
                        {item?.name} <span>({item?.ecommerce?.name})</span>
                    </Text>
                ))}
            </Flex>
        ),
    },
];

export const FinishingTableList = [
    {
        name: 'Action',
        value: 'action',
        width: '80px',
        component: () => (
            <Button className="column-button" bg="#d5d528 !important">
                selesaikan
            </Button>
        ),
    },
    ...tableList,
];

export const TableListComingSoon = [
    {
        name: 'Action',
        value: 'action',
        width: '60px',
        component: ({ values }) => (
            <Button className="column-button" bg={values?.isLive ? '#4e9ead' : '#f36b6b !important'}>
                {'detail'}
            </Button>
        ),
    },
    ...tableList,
];

export const TableListDone = [
    {
        name: 'Action',
        value: 'action',
        width: '60px',
        component: ({ values }) => (
            <Button className="column-button" bg={values?.isLive ? '#4e9ead' : '#f36b6b !important'}>
                {values?.isLive ? 'detail' : 'canceled'}
            </Button>
        ),
    },
    ...tableList,
];

export const initialState = {
    data: [],
    isLoading: true,
};
